import "./App.css";
import Hero from "./sections/Hero";
import Navbar from "./components/Navbar";
import ContactAddress from "./sections/ContactAddress";
import Socials from "./sections/Socials";

function App() {
  return (
    <div className="App">
      <Hero />
      <Navbar />
      <ContactAddress />
      <Socials />
    </div>
  );
}

export default App;
