import CustomButton from "./CustomButton";
import twitter from "../images/Xlogo.png";
import telegram from "../images/telegram.png";
import "./styles/navbar.css";
import { useRef } from "react";

export default function Navbar() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="navbar">
      <div className="navbar-container">
        <button
          className="telegram-button navbar-button"
          onClick={() => {
            window.open("https://t.me/BabyRoostCTO", "_blank");
          }}
        >
          <img className="s-telegram-image" src={telegram} />
        </button>
        <button
          className="twitter-button navbar-button"
          onClick={() => {
            window.open("https://twitter.com/@BabyRoost420", "_blank");
          }}
        >
          <img className="s-twitter-image" src={twitter} />
        </button>
        <button
          className="navbar-button"
          onClick={() => scrollToSection("contact")}
        >
          Contract address
        </button>
        <button
          className="navbar-button"
          onClick={() => scrollToSection("socials")}
        >
          Socials
        </button>
      </div>
    </div>
  );
}

//Twitter
//Telegram
//contact address
//Baby Roosting 101
//Tokenmomics
//FAQ
//Socials
