import "./styles/contactAddress.css";
import contactAddress from "../images/contactAddress.png";
import { useRef } from "react";

export default function ContactAddress({ scrollToContractAddress }) {
  const predefinedText = "0xF854c8E340FEd9F9a30B6d9e2C9baaA4E07f33fE";

  const copyToClipboard = () => {
    const input = document.createElement("input");
    input.value = predefinedText;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    alert("Token Address Copied To Clipboard");
  };

  return (
    <div className="ca-container" id="contact">
      <img src={contactAddress} className="ca-title" alt="Contact Address" />
      <div className="input-container">
        <input className="address" value={predefinedText} readOnly />
        <button className="copy-button" onClick={copyToClipboard} readOnly>
          Copy
        </button>
      </div>
    </div>
  );
}
