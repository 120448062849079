import React from "react";
import "./styles/customButton.css"; // Import your CSS file for styling if needed

const CustomButton = ({ buttonText }) => {
  return (
    <div className="custom-button">
      <span className="button-text">{buttonText}</span>
    </div>
  );
};

export default CustomButton;
