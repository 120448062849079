import "./styles/socials.css";
import title from "../images/socialsTitle.png";
import telegram from "../images/telegram.png";
import twitter from "../images/Xlogo.png";
import { useRef } from "react";

export default function Socials() {
  return (
    <div className="s-container" id="socials">
      <img src={title} className="s-title" />
      <a className="s-text">Join The $BROOST Community</a>
      <div className="s-button-container">
        <button
          className="s-button"
          onClick={() => {
            console.log("hell1o");
          }}
        >
          <img className="s-button-image" src={telegram} />
        </button>
        <button
          className="s-button"
          onClick={() => {
            console.log("hello");
          }}
        >
          <img className="s-twitter-button" src={twitter} />
        </button>
      </div>
    </div>
  );
}
