import header from "../images/header.png";
import hero from "../images/hero.png";
import hero2 from "../images/hero2.png";
import { useState, useEffect } from "react";
import "./styles/hero.css";

export default function Hero() {
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 700px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    const handleScreenChange = () => setIsSmallScreen(mediaQuery.matches);

    mediaQuery.addListener(handleScreenChange);

    return () => {
      mediaQuery.removeListener(handleScreenChange);
    };
  }, []);

  return (
    <div className="container">
      <img src={header} className="title" />
      {isSmallScreen ? (
        <img src={hero2} className="hero2" />
      ) : (
        <img src={hero} className="hero" />
      )}
    </div>
  );
}
